import { Box, Grid } from '@mui/material';
import React from 'react';

type Props = {
  children?: React.ReactNode;
};

export const TwoColumnContainer: React.FC<Props> = ({ children }) => (
  <Box sx={{ flexGrow: 1 }}>
    <Grid container spacing={2}>
      {children}
    </Grid>
  </Box>
);

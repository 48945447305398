import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { MainTemplate } from './components/templates';
import { EventPage, MainPage, SignInPage } from './pages';

export function App() {
  return (
    <BrowserRouter>
      <MainTemplate>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/signin" element={<SignInPage />} />
          <Route path="/events" element={<EventPage />} />
        </Routes>
      </MainTemplate>
    </BrowserRouter>
  );
}

import Container from '@mui/material/Container';
import React from 'react';

type Props = {
  children?: React.ReactNode;
};

const RootContainer: React.FC<Props> = ({ children }) => (
  <Container maxWidth={false} sx={{ bgcolor: 'primary.dark' }}>
    {children}
  </Container>
);

export const Header: React.FC<Props> = ({ children }) => <RootContainer>{children}</RootContainer>;

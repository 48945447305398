import { BoxWithPagination } from 'components/atoms/Boxes/BoxWithPagination';
import { FlexBox } from 'components/atoms/Boxes/FlexBox';
import { VideoProps } from 'components/organism/Media/types/video';
import { VimeoVideo } from 'components/organism/Media/VimeoVideo';
import { YouTubeVideo } from 'components/organism/Media/YouTubeVideo';
import React from 'react';
import { VideoProviderType } from 'sdk/internal/event/enums/VideoProviderType';

type Props = {
  type: VideoProviderType;
} & VideoProps;

const renderVideo = (src: string, type: VideoProviderType, allowFullScreen?: boolean) => {
  return type === VideoProviderType.Vimeo ? (
    <VimeoVideo src={src} allowFullScreen={allowFullScreen} />
  ) : (
    <YouTubeVideo src={src} allowFullScreen={allowFullScreen} />
  );
};

export const Video: React.FC<Props> = ({ src, type, allowFullScreen }) => (
  <BoxWithPagination>
    <FlexBox>{renderVideo(src, type, allowFullScreen)}</FlexBox>
  </BoxWithPagination>
);

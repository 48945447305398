import { Grid } from '@mui/material';
import React from 'react';

type Props = {
  children?: React.ReactNode;
};

export const ColumnContainer: React.FC<Props> = ({ children }) => (
  <Grid item xs={12} md={6}>
    {children}
  </Grid>
);

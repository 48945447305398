import { Typography } from '@mui/material';
import React from 'react';

type Props = {
  children: React.ReactNode;
  align?: 'inherit' | 'left' | 'center';
};

export const SectionSubTitle: React.FC<Props> = ({ children, align, ...props }) => (
  <Typography noWrap variant="h6" textAlign={align} {...props}>
    {children}
  </Typography>
);

import Toolbar from '@mui/material/Toolbar';
import React from 'react';

type Props = {
  children?: React.ReactNode;
};

export const ToolContainer: React.FC<Props> = ({ children }) => (
  <Toolbar
    disableGutters
    sx={{
      display: { xs: 'flex' },
    }}
  >
    {children}
  </Toolbar>
);

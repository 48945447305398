import Container from '@mui/material/Container';
import { FlexBox } from 'components/atoms/Boxes/FlexBox';
import { ContentContainer } from 'components/atoms/Containers/ContentContainer';
import { Copyright } from 'components/molecules/Copyright/Copyright';
import React from 'react';

type Props = {
  children?: React.ReactNode;
};

const RootContainer: React.FC<Props> = ({ children }) => (
  <Container maxWidth={false} sx={{ bgcolor: 'primary.light', position: 'fixed', bottom: 0 }}>
    {children}
  </Container>
);

export const Footer: React.FC<Props> = ({ children }) => (
  <RootContainer>
    <ContentContainer>
      <FlexBox>
        {children}
        <Copyright companyName="Pyrodise" />
      </FlexBox>
    </ContentContainer>
  </RootContainer>
);

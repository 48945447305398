import { CopyrightText } from 'components/atoms/Typography/CopyrightText';
import React from 'react';

type Props = {
  companyName: string;
};

export const Copyright: React.FC<Props> = ({ companyName, ...props }) => (
  <CopyrightText {...props}>{`Copyright © ${companyName} ${new Date().getFullYear()}.`}</CopyrightText>
);

import { ToolContainer } from 'components/atoms';
import { ContentContainer } from 'components/atoms/Containers/ContentContainer';
import { DesktopMenu, HamburgerMenu } from 'components/organism';
import React from 'react';
import { LinkItem } from 'utils/types/linkItem';

type Props = {
  links: LinkItem[];
};

export const Navigation: React.FC<Props> = ({ links }) => {
  return (
    <ContentContainer>
      <ToolContainer>
        <DesktopMenu links={links} />
        <HamburgerMenu links={links} />
      </ToolContainer>
    </ContentContainer>
  );
};

import { Address, Event } from 'sdk/internal/event/types/event';
import { EventOverview } from 'sdk/internal/event/types/eventOverview';
import { getFormattedDay, getFormattedTimeRange } from 'utils/functions/date/idnex';

const getFormattedAddress = ({ city, country, state, street, zipCode }: Address) =>
  `${street} ${city} ${zipCode} ${state}, ${country}`;

export const getEventOverview = (event: Event): EventOverview => ({
  id: event.id,
  name: event.name,
  date: getFormattedDay(new Date(event.startDate)),
  time: getFormattedTimeRange(new Date(event.startTime), new Date(event.startTime)),
  website: event.website,
  organizer: event.organizer,
  bestPlaceToWatch: event?.bestPlaceToWatch ? getFormattedAddress(event.bestPlaceToWatch) : '-',
  eventParking: event?.eventParking ? getFormattedAddress(event.eventParking) : '-',
  eventLocation: getFormattedAddress(event.eventLocation),
  description: event.description,
  festival: event?.festival || '-',
});

import { ColumnContainer, ContentContainer, TwoColumnContainer } from 'components/atoms';
import { EventOverview } from 'components/EventOverview/EventOverview';
import { FireworksShown } from 'components/FireworksShown/FireworksShown';
import { Video } from 'components/organism/Media/Video';
import React, { useEffect, useState } from 'react';
import { eventById, fireworksByEventId } from 'sdk/internal/event/endpoints';
import { VideoProviderType } from 'sdk/internal/event/enums/VideoProviderType';
import { getEventOverview } from 'sdk/internal/event/functions/getEventOverview';
import { EventOverview as EventOverviewType } from 'sdk/internal/event/types/eventOverview';
import { Fireworks } from 'sdk/internal/event/types/fireworks';

type EventPageData = {
  fireworks?: Fireworks;
  eventOverview?: EventOverviewType;
  youtubeVideo?: string;
  vimeoVideo?: string;
};

const getEventPageData = async (eventId: number) => {
  const [fireworks, eventData] = await Promise.all([fireworksByEventId(eventId), eventById(eventId)]);
  const eventOverview = getEventOverview(eventData);
  const youtubeVideo = 'https://www.youtube.com/embed/qxzx7Gy4vJs';
  const vimeoVideo = 'https://player.vimeo.com/video/156232471?h=0bb4d6ff35';

  return {
    fireworks,
    eventOverview,
    youtubeVideo,
    vimeoVideo,
  };
};

export const EventPage: React.FC = () => {
  const [eventPageData, setEventPageData] = useState<EventPageData>({});
  useEffect(() => {
    (async () => {
      const data = await getEventPageData(123);
      setEventPageData(data);
    })();
  }, []);

  return (
    <ContentContainer>
      <TwoColumnContainer>
        <ColumnContainer>
          <EventOverview enableLikeButton={true} isEventOnLikeList={false} eventData={eventPageData.eventOverview} />
          <FireworksShown fireworks={eventPageData.fireworks} />
        </ColumnContainer>
        <ColumnContainer>
          {eventPageData?.youtubeVideo && <Video type={VideoProviderType.YouTube} src={eventPageData.youtubeVideo} />}
          {eventPageData?.vimeoVideo && <Video type={VideoProviderType.Vimeo} src={eventPageData.vimeoVideo} />}
        </ColumnContainer>
      </TwoColumnContainer>
    </ContentContainer>
  );
};

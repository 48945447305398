import { VideoProps } from 'components/organism/Media/types/video';
import React from 'react';

type Props = VideoProps;

export const YouTubeVideo: React.FC<Props> = ({ src, allowFullScreen }) => (
  <iframe
    src={src}
    style={{
      aspectRatio: 16 / 9,
      width: '100%',
    }}
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen={allowFullScreen}
    title={'YouTube video player'}
  />
);

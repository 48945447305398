import { LinkProps, Link as MuiLink } from '@mui/material';
import React from 'react';

type Props = {
  children?: React.ReactNode;
} & LinkProps;

export const Link: React.FC<Props> = ({ children, ...rest }) => {
  return <MuiLink {...rest}>{children}</MuiLink>;
};

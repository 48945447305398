import { VideoProps } from 'components/organism/Media/types/video';
import React from 'react';

type Props = VideoProps;

export const VimeoVideo: React.FC<Props> = ({ src, allowFullScreen }) => (
  <iframe
    style={{
      aspectRatio: 16 / 9,
      width: '100%',
    }}
    title="vimeo-player"
    src={src}
    frameBorder="0"
    allowFullScreen={allowFullScreen}
  />
);

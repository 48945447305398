import { IconButton } from 'components/atoms/Buttons/IconButton';
import { HamburgerIcon } from 'components/atoms/Icons/HamburgerIcon';
import React from 'react';

type Props = {
  ariaLabel?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

export const HamburgerButton: React.FC<Props> = ({ ariaLabel, onClick }) => (
  <IconButton ariaLabel={ariaLabel} onClick={onClick}>
    <HamburgerIcon />
  </IconButton>
);

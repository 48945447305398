import { MenuItem, Typography, styled } from '@mui/material';
import Box from '@mui/material/Box';
import { Link } from 'components/atoms/Link/Link';
import React from 'react';
import { LinkItem } from 'utils/types/linkItem';

type Props = {
  links: LinkItem[];
};

const StyledMenuItem = styled(Box)(`
 &  a {
 color: #FFF;
 text-decoration: none;
 }
 & > :first-child {
   padding-left: 0;
  }
 &:last-child {
    padding-right: 0;
  }
  `);

export const DesktopMenu: React.FC<Props> = ({ links }) => (
  <StyledMenuItem sx={{ flexDirection: 'row', display: { xs: 'none', md: 'flex' } }}>
    {links.map((link, index) => (
      <MenuItem key={index}>
        <Typography component={Link} noWrap variant="h5" textAlign="center" href={link.href}>
          {link.label}
        </Typography>
      </MenuItem>
    ))}
  </StyledMenuItem>
);

import { BoxWithPagination } from 'components/atoms/Boxes/BoxWithPagination';
import { FlexBox } from 'components/atoms/Boxes/FlexBox';
import { DescriptionText, SectionSubTitle, SectionTitle } from 'components/atoms/Typography';
import { LikeButton } from 'components/molecules/Buttons/LikeButton';
import React from 'react';
import { EventOverview as EventOverviewType } from 'sdk/internal/event/types/eventOverview';

type Props = {
  eventData?: EventOverviewType;
  onLikeClick?: (id: number) => void;
  enableLikeButton?: boolean;
  isEventOnLikeList?: boolean;
};

export const EventOverview: React.FC<Props> = ({ eventData, onLikeClick, isEventOnLikeList, enableLikeButton }) => (
  <BoxWithPagination>
    {eventData && (
      <>
        <FlexBox justifyContent={'space-between'}>
          <SectionTitle>{eventData.name}</SectionTitle>
          <LikeButton
            disabled={!enableLikeButton}
            label={enableLikeButton && isEventOnLikeList ? 'saved' : 'save'}
            onClick={() => {
              onLikeClick?.(eventData.id);
            }}
          />
        </FlexBox>
        <BoxWithPagination>
          <DescriptionText>
            <b>Date:</b> {eventData.date}
          </DescriptionText>
          <DescriptionText>
            <b>Time:</b> {eventData.time}
          </DescriptionText>
          <DescriptionText>
            <b>Website:</b> {eventData.website}
          </DescriptionText>
          <DescriptionText>
            <b>Organizer:</b> {eventData.organizer}
          </DescriptionText>
          <DescriptionText>
            <b>Address:</b> {eventData.eventLocation}
          </DescriptionText>
          <DescriptionText>
            <b>BestPlaceToWatch</b>: {eventData.bestPlaceToWatch}
          </DescriptionText>
        </BoxWithPagination>
        <BoxWithPagination>
          <SectionSubTitle>Share through:</SectionSubTitle>
        </BoxWithPagination>
        <DescriptionText align="justify">{eventData.description}</DescriptionText>
      </>
    )}
  </BoxWithPagination>
);

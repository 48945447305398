import { BoxWithPagination } from 'components/atoms/Boxes/BoxWithPagination';
import { SectionTitle } from 'components/atoms/Typography/SectionTitle';
import { FireworksGrid } from 'components/organism/Grid/FireworksGrid';
import React from 'react';
import { Fireworks } from 'sdk/internal/event/types/fireworks';

type Props = {
  fireworks?: Fireworks;
};

const pageSize = 15;

export const FireworksShown: React.FC<Props> = ({ fireworks = [] }) => (
  <BoxWithPagination>
    <SectionTitle>Fireworks shown</SectionTitle>
    <FireworksGrid fireworks={fireworks} pageSize={pageSize} />
  </BoxWithPagination>
);

import { Typography } from '@mui/material';
import { Button } from 'components/atoms/Buttons/Button';
import { HeartIcon } from 'components/atoms/Icons/HeartIcon';
import React from 'react';

type Props = {
  label: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
};

export const LikeButton: React.FC<Props> = ({ label, onClick, disabled }) => (
  <Button onClick={onClick} disabled={disabled}>
    <HeartIcon />{' '}
    <Typography noWrap variant="h6" textAlign="center" sx={{ ml: 2 }}>
      {label}{' '}
    </Typography>
  </Button>
);

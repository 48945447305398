import { MenuItem, Typography, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu/Menu';
import { HamburgerButton } from 'components/molecules/Buttons/HamburgerButton';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LinkItem } from 'utils/types/linkItem';

type Props = {
  links: LinkItem[];
};

const StyledHamburgerMenu = styled(Box)(`
 & > :first-child {
 color: #FFF;
   padding-left: 0;
  }
  `);

export const HamburgerMenu: React.FC<Props> = ({ links }) => {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOnClickLink = (path: string) => {
    navigate(path);
  };

  return (
    <StyledHamburgerMenu sx={{ flexDirection: 'row', display: { xs: 'flex', md: 'none' } }}>
      <HamburgerButton onClick={handleOpenNavMenu} />
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: 'block', md: 'none' },
        }}
      >
        {links.map((link, index) => (
          <MenuItem key={index} onClick={handleCloseNavMenu}>
            <Typography
              textAlign="center"
              onClick={() => {
                handleOnClickLink(link.href);
              }}
            >
              {link.label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </StyledHamburgerMenu>
  );
};

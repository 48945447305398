import { ButtonProps, Button as MuiButton, styled } from '@mui/material';
import React from 'react';

type Props = {
  children?: React.ReactNode;
} & ButtonProps;

const StyledButton = styled(MuiButton)(`
width: fit-content;
`);

export const Button: React.FC<Props> = ({ children, ...rest }) => (
  <StyledButton type="button" fullWidth variant="contained" {...rest}>
    {children}
  </StyledButton>
);

import Typography from '@mui/material/Typography';
import React from 'react';

type Props = {
  children: React.ReactNode;
};

export const CopyrightText: React.FC<Props> = ({ children, ...props }) => (
  <Typography variant="body2" color="text.secondary" align="center" {...props}>
    {children}
  </Typography>
);

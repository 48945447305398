import Box from '@mui/material/Box';
import React from 'react';

type Props = {
  direction?: 'row' | 'row-reverse' | 'column';
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
  children: React.ReactNode;
  hideOnMobile?: boolean;
  hideOnDesktop?: boolean;
  height?: number | string;
};

export const FlexBox: React.FC<Props> = ({
  direction = 'row',
  hideOnMobile,
  hideOnDesktop,
  children,
  justifyContent = 'flex-start',
  height = '100%',
}) => {
  const styleHideOnMobile = hideOnMobile ? { display: { xs: 'none', md: 'flex' } } : {};
  const styleHideOnDesktop = hideOnDesktop ? { display: { xs: 'flex', md: 'none' } } : {};

  return (
    <Box
      sx={{
        flexDirection: direction,
        justifyContent,
        height,
        pt: 2,
        pb: 2,
        display: 'flex',
        ...styleHideOnMobile,
        ...styleHideOnDesktop,
      }}
    >
      {children}
    </Box>
  );
};

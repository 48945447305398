import { Fireworks } from 'sdk/internal/event/types/fireworks';

export const fireworksByEventId = async (eventId: number): Promise<Fireworks> => {
  // TODO implement api call+
  const mock = {
    eventId,
    fireworks: [
      {
        id: 1,
        name: 'Roman candies 1',
        caliber: 2,
        quantity: 6,
        brand: 'brand',
        type: 'type 2',
      },
      {
        id: 2,
        name: 'Roman candies 2',
        caliber: 2,
        quantity: 6,
        brand: 'brand',
        type: 'type 2',
      },
      {
        id: 3,
        name: 'Roman candies 3',
        caliber: 2,
        quantity: 6,
        brand: 'brand',
        type: 'type 2',
      },
      {
        id: 4,
        name: 'Roman candies 4',
        caliber: 2,
        quantity: 6,
        brand: 'brand',
        type: 'type 2',
      },
      {
        id: 5,
        name: 'Roman candies 5',
        caliber: 2,
        quantity: 6,
        brand: 'brand',
        type: 'type 2',
      },
      {
        id: 6,
        name: 'Roman candies 6',
        caliber: 2,
        quantity: 6,
        brand: 'brand',
        type: 'type 2',
      },
      {
        id: 7,
        name: 'Roman candies 7',
        caliber: 2,
        quantity: 6,
        brand: 'brand',
        type: 'type 2',
      },
    ],
  };

  return mock.fireworks;
};

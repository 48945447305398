import { FullWidthContainer } from 'components/atoms';
import { Footer, Header } from 'components/organism';
import { Navigation } from 'components/organism/Navigation/Navigation';
import React from 'react';
import { getNavigationLinks } from 'utils/functions/navigation';

type Props = {
  children: React.ReactNode;
};

const navigationLinks = getNavigationLinks();

export const MainTemplate: React.FC<Props> = ({ children }) => (
  <>
    <Header>
      <Navigation links={navigationLinks} />
    </Header>
    <FullWidthContainer>{children}</FullWidthContainer>
    <Footer />
  </>
);

import { Typography } from '@mui/material';
import React from 'react';

type Props = {
  children: React.ReactNode;
  align?: 'center' | 'inherit' | 'justify' | 'left' | 'right';
};

export const DescriptionText: React.FC<Props> = ({ children, align, ...props }) => (
  <Typography noWrap textAlign={align} {...props}>
    {children}
  </Typography>
);

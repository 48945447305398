import { IconButton as Button } from '@mui/material';
import React from 'react';

type Props = {
  ariaLabel?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  children?: React.ReactNode;
};

export function IconButton({ ariaLabel, onClick, children }: Props) {
  return (
    <Button
      size="large"
      aria-label={ariaLabel}
      aria-controls="menu-appbar"
      aria-haspopup="true"
      onClick={onClick}
      color="inherit"
    >
      {children}
    </Button>
  );
}

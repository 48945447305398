import { DataGrid } from '@mui/x-data-grid';
import { FlexBox } from 'components/atoms/Boxes/FlexBox';
import React from 'react';
import { Fireworks } from 'sdk/internal/event/types/fireworks';

type Props = {
  fireworks: Fireworks;
  pageSize: number;
};

const columns = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 25,
    editable: false,
    sortable: false,
  },
  {
    field: 'caliber',
    headerName: 'Caliber',
    flex: 15,
    type: 'number',
    editable: false,
    sortable: false,
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    flex: 15,
    type: 'number',
    editable: false,
    sortable: false,
  },
  {
    field: 'brand',
    headerName: 'Brand',
    flex: 25,
    editable: false,
    sortable: false,
  },
  {
    field: 'type',
    headerName: 'type',
    flex: 20,
    editable: false,
    sortable: false,
  },
];

export const FireworksGrid: React.FC<Props> = ({ fireworks, pageSize }) => (
  <FlexBox height={400}>
    <DataGrid
      rows={fireworks}
      columns={columns}
      pageSize={pageSize}
      pagination={undefined}
      disableColumnMenu
      disableColumnSelector
      disableColumnFilter
    />
  </FlexBox>
);
